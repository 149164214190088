import { InfoItem } from './InfoItem'
import { ShieldCheck } from './icons/ShieldCheck'
import { StoneCrafting } from './icons/StoneCrafting'
import { SupportAgent } from './icons/SupportAgent'
import { ThumbUp } from './icons/ThumbUp'

export const HomePageInfoSection = () => (
  <section className=' py-2 px-4 2xl:px-[280px] xl:px-[150px] lg:px-[80px] lg:py-[44px] pt-12 md:pt-2'>
    <ul className='hidden md:flex flex-wrap md:flex-nowrap columns-2 md:columns-[auto] xl:gap-[120px] 2xl:gap-[150px] uppercase text-sm justify-center'>
      <InfoItem
        icon={<ThumbUp className='text-[#7E492A]' />}
        text='Pure Copper Excellence'
      />
      <div className='hidden sm:flex '>
        <InfoItem
          icon={<ShieldCheck className='text-[#7E492A]' />}
          text='100% AUTHENTIC PRODUCTS'
        />
      </div>

      <InfoItem
        icon={<StoneCrafting className='text-[#7E492A]' />}
        text='Committed To Craftsmanship'
      />

      <div className='block sm:hidden mb-12 mt-12 md:mt-0 md:mb-0'>
        <InfoItem
          icon={<ShieldCheck className='text-[#7E492A]' />}
          text='100% AUTHENTIC PRODUCTS'
        />
      </div>

      <InfoItem
        icon={<SupportAgent className='text-[#7E492A]' />}
        text='24x7 Customer Support, 14 Day Returns'
      />
    </ul>
    <div className='md:hidden'>
      <div className='flex mb-8'>
        <InfoItem
          className='flex-1 w-1/2'
          icon={<ThumbUp className='text-[#7E492A]' />}
          text='Pure Copper Excellence'
        />
        <InfoItem
          className='flex-1 w-1/2'
          icon={<ShieldCheck className='text-[#7E492A]' />}
          text='100% AUTHENTIC PRODUCTS'
        />
      </div>
      <div className='flex mb-8'>
        <InfoItem
          className='flex-1 w-1/2'
          icon={<StoneCrafting className='text-[#7E492A]' />}
          text='Committed To Craftsmanship'
        />
        <InfoItem
          className='flex-1 w-1/2'
          icon={<SupportAgent className='text-[#7E492A]' />}
          text='24x7 Customer Support, 14 Day Returns'
        />
      </div>
    </div>
  </section>
)
