import s from './index.module.css'

interface IInfoItemProps {
  text: string
  icon: JSX.Element
  className?: string
}
export const InfoItem = ({ text, icon, className }: IInfoItemProps) => (
  <li
    className={`flex items-start md:items-center gap-[10px] text-[13px] md:text-[1rem] ${s.infoItem} ${className}`}
  >
    {icon}
    <span className='font-bold uppercase'>{text}</span>
  </li>
)
