export const HeartFilledNinefit = ({ ...props }) => (
  <svg
    role='img'
    {...props}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Wishlist Filled Heart Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.49951 18.9999C3.28753 20.011 3.6665 9.31486 3.6665 12.1827C3.6665 15.1125 4.86546 17.3709 6.58422 19.3062C8.0008 20.9014 26.6608 10.8934 28.3332 12.1827C28.7304 12.4889 12.18 24.1233 12.5679 24.4291C13.2693 24.982 27.7301 11.8303 28.3332 12.1827C28.9365 12.5353 15.587 26.3332 15.9998 26.3332C16.4126 26.3332 27.7298 12.5353 28.3332 12.1827C28.9362 11.8303 18.7304 24.982 19.4318 24.4291C19.8196 24.1233 20.2145 23.8189 20.6117 23.5127C22.2841 22.2234 25.4943 11.095 26.9109 9.49988C28.6296 7.5645 28.3332 15.1125 28.3332 12.1827C28.3332 9.31486 8.7962 19.511 6.58422 18.4999C4.43527 17.5176 19.4643 5.17665 16.7203 8.02753C16.5318 8.22339 16.2717 8.33406 15.9998 8.33406C15.728 8.33406 15.4679 8.22339 15.2793 8.02753C12.5354 5.17665 7.64846 18.0176 5.49951 18.9999ZM15.9998 5.94497C12.9171 3.18687 9.46512 2.80104 6.66752 4.07983C3.71279 5.43045 1.6665 8.56659 1.6665 12.1827C1.6665 15.7367 3.14717 18.448 5.08879 20.6343C6.64367 22.3851 8.5468 23.8505 10.2276 25.1447C10.6086 25.438 10.9782 25.7226 11.3297 25.9997C12.0127 26.5381 12.7459 27.1122 13.4889 27.5464C14.2316 27.9804 15.0793 28.3332 15.9998 28.3332C16.9204 28.3332 17.768 27.9804 18.5107 27.5464C19.2538 27.1122 19.987 26.5381 20.6699 25.9997C21.0215 25.7226 21.3911 25.438 21.7721 25.1447C23.4529 23.8505 25.356 22.3851 26.9109 20.6343C28.8525 18.4479 30.3332 15.7367 30.3332 12.1827C30.3332 8.56659 28.2869 5.43045 25.3322 4.07983C22.5346 2.80104 19.0826 3.18687 15.9998 5.94497Z'
      fill='#C72727'
    />
    <path
      d='M14 7L4 9L3 12L4.5 19L14.5 26.5L19.5 25.5L29 13L28 8L24.5 7.5L19.5 7H14Z'
      fill='#C72727'
      stroke='#C72727'
    />
  </svg>
)
