import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { useMemo } from 'react'
import { useCommerce } from '..'

const findLastIndex = (arr: Array<any>) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (!Number.isNaN(Number(arr[i])) && arr[i].trim()) {
      return i
    }
  }
  return -1
}

export const configureFormat = ({ format }: { format: string }) => {
  let index = 0
  let lastNumberIndex = 0

  index = Number(
    [...format].findIndex(item => !Number.isNaN(Number(item)) && item.trim()),
  )

  lastNumberIndex = Number(
    findLastIndex([...format])
  )

  return { index, lastNumberIndex }
}

export function formatPrice({
  amount,
  format = '',
  decimalMark,
  thousandsSeparator,
  defaultCurrency,
  currencySymbol,
}: {
  amount: number
  format: string
  decimalMark: string
  thousandsSeparator: string
  defaultCurrency: string
  currencySymbol: string
}) {
  return format
  .replace('%u', currencySymbol)
  .replace('%c', defaultCurrency)
  .replace(
    '%n',
    amount
      .toFixed(2)
      .replace('.', decimalMark ?? '.')
      .replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator || (thousandsSeparator === null ? '' : ','),
      ),
  )
}

export function formatVariantPrice({
  amount,
  baseAmount,
  locale,
  format = '',
  decimalMark,
  thousandsSeparator,
  defaultCurrency,
  currencySymbol,
}: {
  baseAmount: number
  amount: number
  locale: string
  format: string
  decimalMark: string
  thousandsSeparator: string
  defaultCurrency: string
  currencySymbol: string
}) {
  const hasDiscount = baseAmount > amount
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' })
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null

  const price = formatPrice({
    amount,
    format,
    decimalMark,
    thousandsSeparator,
    defaultCurrency,
    currencySymbol
  })
  const basePrice = hasDiscount
    ? formatPrice({
        amount: baseAmount,
        format,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol
      })
    : null

  return { price, basePrice, discount }
}

export default function usePrice(
  data?: {
    amount: number
    baseAmount?: number
    currencyCode: string
    format: string
  } | null,
) {
  const {
    data: {
      attributes: {
        decimal_mark: decimalMark,
        thousands_separator: thousandsSeparator,
        default_currency: defaultCurrency,
        currency_symbol: currencySymbol,
      },
    },
  } = useStoreDataContext()

  const { amount, baseAmount, currencyCode, format = '' } = data ?? {}

  const { locale } = useCommerce()
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return ''

    return baseAmount
      ? formatVariantPrice({
          amount,
          baseAmount,
          locale,
          format,
          decimalMark,
          thousandsSeparator,
          defaultCurrency,
          currencySymbol,
        })
      : formatPrice({ amount, format, decimalMark, thousandsSeparator, defaultCurrency, currencySymbol })
  }, [
    amount,
    baseAmount,
    format,
    locale,
    currencyCode,
    decimalMark,
    thousandsSeparator,
  ])

  return typeof value === 'string' ? { price: value } : value
}
