export const StoneCrafting = ({ className }: { className?: string }) => (
  <svg
    width='30'
    height='28'
    viewBox='0 0 30 28'
    fill='none'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.047 0.466797L18.9786 5.67767L25.9258 12.6327L29.5646 8.99017L21.047 0.466797ZM11.0255 0.792922C4.88484 4.9788 0.596094 10.8708 0.433594 18.8495C4.32422 11.361 10.5872 4.7813 16.3848 1.99017L11.0255 0.792922ZM5.10947 1.75967L3.26178 3.60742C3.71653 4.04242 4.16991 4.47573 4.62509 4.91011C5.18053 4.26073 5.77322 3.63823 6.39466 3.04092L5.10947 1.75967ZM27.2228 1.75967L25.6056 3.37492L27.4005 5.1718C27.9573 4.6493 28.5143 4.1263 29.0705 3.60342L27.2228 1.75967ZM10.7267 7.36905C10.1248 7.91477 9.53991 8.47885 8.97272 9.06048C10.5517 10.5686 12.1306 12.0807 13.7032 13.6074L15.34 11.9745L10.7267 7.36898V7.36905ZM20.3087 8.66405L3.25009 25.6971L5.08216 27.5234C10.2997 21.5152 16.067 15.8672 21.9454 10.3026L20.3087 8.66386L20.3087 8.66405ZM18.5431 15.1737C18.0003 15.6993 17.4593 16.2267 16.92 16.7559C20.4135 20.2171 23.8678 23.7779 27.2423 27.5332L29.0822 25.6973L18.5431 15.1737V15.1737Z'
      fill='#7E492A'
    />
  </svg>
)
