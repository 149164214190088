const ArrowDoubleRight = ({ ...props }) => (
  <svg
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.5 2.18783L1.91 0.777832L7.91 6.77783L1.91 12.7778L0.5 11.3678L5.08 6.77783L0.5 2.18783ZM5.08984 2.18783L6.49984 0.777832L12.4998 6.77783L6.49984 12.7778L5.08984 11.3678L9.66984 6.77783L5.08984 2.18783Z'
      fill='currentColor'
    />
  </svg>
)

export default ArrowDoubleRight
