export const FilledHeartThird = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Filled Heart Icon</title>
    <path
      d='M12.2386 21.4242C12.1076 21.4256 11.9786 21.3925 11.8644 21.3283C11.4518 21.098 1.875 15.6571 1.875 8.75758C1.875 7.54916 2.25521 6.37138 2.96179 5.39106C3.66836 4.41075 4.66547 3.67759 5.81187 3.29546C6.95827 2.91333 8.19585 2.90158 9.3493 3.26189C10.5027 3.6222 11.5136 4.3363 12.2386 5.30303C12.9637 4.3363 13.9745 3.6222 15.128 3.26189C16.2814 2.90158 17.519 2.91333 18.6654 3.29546C19.8118 3.67759 20.8089 4.41075 21.5155 5.39106C22.2221 6.37138 22.6023 7.54916 22.6023 8.75758C22.6023 11.6939 20.9038 14.7071 17.5548 17.7202C16.0341 19.0873 14.3782 20.2962 12.6129 21.3283C12.4987 21.3925 12.3696 21.4256 12.2386 21.4242ZM7.63258 4.53535C6.51277 4.53535 5.43883 4.98019 4.64701 5.77201C3.85519 6.56383 3.41035 7.63777 3.41035 8.75758C3.41035 14.0545 10.5114 18.7086 12.2386 19.7641C13.9659 18.7086 21.0669 14.0545 21.0669 8.75758C21.0678 7.7815 20.7305 6.83526 20.1124 6.07986C19.4942 5.32446 18.6335 4.80655 17.6765 4.61425C16.7196 4.42196 15.7255 4.56716 14.8636 5.02514C14.0016 5.48313 13.325 6.22561 12.9487 7.12626C12.8909 7.26706 12.7925 7.38749 12.6661 7.47224C12.5396 7.557 12.3909 7.60225 12.2386 7.60225C12.0864 7.60225 11.9376 7.557 11.8112 7.47224C11.6848 7.38749 11.5864 7.26706 11.5285 7.12626C11.209 6.35692 10.6681 5.69988 9.9744 5.23858C9.28074 4.77727 8.46563 4.5325 7.63258 4.53535Z'
      fill='currentColor'
    />
    <path
      d='M4 5L7.5 4L9 4.6L12.5 6.5L15.5 4.5L19 4L21 6.5L21.5 10.5L15.5 18L11.5 20.5L7 17L3.5 12L3 8L4 5Z'
      fill='currentColor'
    />
    <path
      d='M10 5L9 4.6M9 4.6L7.5 4L4 5L3 8L3.5 12L7 17L11.5 20.5L15.5 18L21.5 10.5L21 6.5L19 4L15.5 4.5L12.5 6.5L9 4.6Z'
      stroke='currentColor'
    />
  </svg>
)
