export const ThumbUp = ({ className }: { className?: string }) => (
  <svg
    width='28'
    height='30'
    viewBox='0 0 28 30'
    className={className}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.9407 26.6959L25.5207 14.0293C25.5767 13.8309 25.586 13.6223 25.5479 13.4198C25.5098 13.2173 25.4252 13.0264 25.3009 12.862C25.1765 12.6977 25.0158 12.5644 24.8312 12.4727C24.6467 12.3809 24.4434 12.3332 24.2373 12.3333H16.5833C16.4736 12.3333 16.3653 12.3085 16.2665 12.2608C16.1677 12.2132 16.0809 12.1438 16.0126 12.058C15.9443 11.9721 15.8962 11.8719 15.872 11.7649C15.8478 11.6579 15.848 11.5468 15.8727 11.4399L16.206 9.99725C16.5113 8.67392 16.6093 7.31059 16.4967 5.95725L16.4353 5.22192C16.3696 4.43305 16.0264 3.693 15.4667 3.13325C14.9547 2.62113 14.2602 2.33336 13.536 2.33325H13.376C12.938 2.33325 12.5347 2.57059 12.3227 2.95325L10.55 6.14325C9.75532 7.57393 8.57071 8.74953 7.134 9.53325L2.69467 11.9533C2.4842 12.0681 2.30859 12.2376 2.18634 12.4438C2.06409 12.6501 1.99972 12.8855 2 13.1253V26.3333C2 26.6869 2.14048 27.026 2.39053 27.2761C2.64057 27.5261 2.97971 27.6666 3.33333 27.6666H20.658C20.9483 27.6665 21.2307 27.5717 21.4622 27.3965C21.6937 27.2213 21.8617 26.9753 21.9407 26.6959Z'
      stroke='#7E492A'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
