import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { useMemo } from 'react'
import { useCommerce } from '..'
import { formatPrice, formatVariantPrice } from './use-price'

export default function useCompareAtPrice(
  data?: {
    amount: number,
    compareAtPrice: number,
    baseAmount?: number,
    currencyCode: string,
    format: string,
  } | null,
) {
  const {
    data: {
      attributes: {
        decimal_mark: decimalMark,
        thousands_separator: thousandsSeparator,
        default_currency: defaultCurrency,
        currency_symbol: currencySymbol,
      },
    },
  } = useStoreDataContext()
  const {
    amount,
    compareAtPrice = 0,
    baseAmount,
    currencyCode,
    format = '',
  } = data ?? {}
  const { locale } = useCommerce()
  const value = useMemo(() => {
    if (
      typeof compareAtPrice !== 'number' ||
      !currencyCode ||
      amount! >= compareAtPrice
    )
      return { price: null }

    return baseAmount
      ? formatVariantPrice({
        amount: compareAtPrice,
        baseAmount,
        locale,
        format,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol
      })
      : formatPrice({
        amount: compareAtPrice,
        format,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol
      })
  }, [
    compareAtPrice,
    baseAmount,
    currencyCode,
    decimalMark,
    thousandsSeparator,
    amount,
    locale,
    format,
  ])

  return typeof value === 'string'
    ? { price: compareAtPrice === 0 ? null : value }
    : value
}
