export const ArrowRightCopperStudio = (props: any) => (
  <svg
    width='20'
    height='10'
    viewBox='0 0 20 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.0125 3.75H0V6.25H15.0125V10L20 5L15.0125 0V3.75Z'
      fill='white'
    />
  </svg>
)
