import { CmsPage } from '@models'
import cn from 'classnames'

import styles from '../BlogsSection.module.css'

interface BlogBannerProps {
  banner: CmsPage
}

export const BlogBanner = ({ banner }: BlogBannerProps) => (
  <div
    style={{
      background: `url('${banner.attributes.img_one_lg}') center center no-repeat`,
      backgroundSize: 'cover',
    }}
    className={cn(
      'rounded-banner h-[439px] flex items-end',
      styles['blog-banner-container'],
    )}
  >
    {/* <img src={banner.attributes.img_one_lg || ''} alt='Blog banner' /> */}
    <div
      style={{
        borderBottomRightRadius: 'var(--border-radius-banner)',
        borderBottomLeftRadius: 'var(--border-radius-banner)',
      }}
      className='bg-white w-full sm:h-[149px] px-7 py-4 flex flex-col gap-2'
    >
      {/* <p className='text-sm font-bold'>
        Posted on <span className='text-brand-hover'>14 september, 2023</span>
      </p> */}
      <h3 className='text-base font-bold'>{banner.attributes.content.title}</h3>
      <p
        className={cn('text-sm text-ellipsis overflow-hidden', [
          styles.blogDesc,
        ])}
      >
        {banner.attributes.content.subtitle}
      </p>
      {banner.attributes.content.blog_url ? (
        <div className='flex'>
          <a
            href={banner.attributes.content.blog_url}
            target='_blank'
            className='border-brand text-sm leading-6 border-b-[1px] text-brand cursor-pointer font-bold'
            rel='noreferrer'
          >
            Read more
          </a>
        </div>
      ) : null}
    </div>
  </div>
)
