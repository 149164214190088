export const HeartFilledIconCopperStudio = (props: any) => (
  <svg
    width='26'
    height='23'
    viewBox='0 0 26 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M25.625 8.375C25.5 4.125 22.125 0.75 17.875 0.75C16.5 0.75 14.375 1.75 13.5 3.375C13.375 3.75 12.875 3.75 12.75 3.375C11.75 1.875 9.75 0.875 8.25 0.875C4.125 0.875 0.625 4.25 0.5 8.375V8.625C0.5 10.75 1.375 12.75 2.875 14.25C2.875 14.25 2.875 14.25 2.875 14.375C3 14.5 9 19.75 11.75 22.125C12.5 22.75 13.625 22.75 14.375 22.125C17.125 19.75 23 14.5 23.25 14.375C23.25 14.375 23.25 14.375 23.25 14.25C24.75 12.875 25.625 10.875 25.625 8.625V8.375Z'
      fill='#7E492A'
    />
  </svg>
)
