export const HeartIconCopperStudio = (props: any) => (
  <svg
    width='26'
    height='23'
    viewBox='0 0 26 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.125 23C12.5 23 11.75 22.75 11.25 22.25C3.125 15.125 3 15 3 14.875L2.875 14.75C1.375 13.25 0.5 11.125 0.5 9V8.75C0.625 4.25 4.25 0.75 8.75 0.75C10.125 0.75 12 1.5 13.125 3C14.25 1.5 16.25 0.75 17.625 0.75C22.125 0.75 25.625 4.25 25.875 8.75V9C25.875 11.25 25 13.25 23.5 14.875L23.375 15C23.25 15.125 22.25 16 15.125 22.375C14.5 22.75 13.875 23 13.125 23ZM5.58452 14.75C6.08452 15.25 7.875 16.75 12.5 20.75C12.875 21.125 13.375 21.125 13.75 20.75C18.5 16.5 21.25 14.125 21.875 13.625L22 13.5C23.25 12.25 23.875 10.625 23.875 9V8.75C23.75 5.25 21 2.625 17.5 2.625C16.625 2.625 14.875 3.25 14.25 4.625C14 5.125 13.5 5.375 13 5.375C12.5 5.375 12 5.125 11.75 4.625C11.125 3.375 9.5 2.625 8.5 2.625C5.125 2.625 2.875 5.625 2.75 9V9.375C2.75 11 3.5 12.625 4.625 13.75L5.58452 14.75Z'
      fill='#898984'
    />
  </svg>
)
