export const CarouselButtonLeft = ({ ...props }) => (
  <svg
    width={16}
    height={48}
    viewBox='0 0 16 48'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2702 0L16 0.969659L2.30638 24L16 47.0303L14.2702 48L0 24L14.2702 0Z'
    />
  </svg>
)
