export const CarouselButtonRight = ({ ...props }) => (
  <svg
    width={16}
    height={48}
    viewBox='0 0 16 48'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.72984 48L8.47703e-08 47.0303L13.6936 24L4.11152e-06 0.96966L1.72984 -1.24754e-06L16 24L1.72984 48Z'
    />
  </svg>
)
