export const FilledHeart = ({ ...props }) => (
  <svg
    role='img'
    width='27'
    height='23'
    viewBox='0 0 27 23'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Filled Heart Icon</title>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.6992 21.9585C13.7032 21.9568 13.7072 21.955 13.7112 21.9531C15.7526 21.0174 18.801 19.1888 21.3478
      16.7236C23.8876 14.2651 26 11.1027 26 7.50541C26 5.26576 25.2705 3.65392 24.1022 2.59069C22.9453 1.53773
      21.4148 1.07434 19.8967 1.00836C18.3783 0.942372 16.8297 1.27093 15.5854 1.85135C14.7424 2.24462 13.9882
      2.77897 13.5 3.42783C13.0118 2.77897 12.2576 2.24462 11.4146 1.85135C10.1703 1.27093 8.62166 0.942372 7.10328
      1.00836C5.58524 1.07434 4.05474 1.53773 2.89776 2.59069C1.72951 3.65392 1 5.26576 1 7.50541C1 11.1027 3.11236
      14.2651 5.65218 16.7236C8.19902 19.1888 11.2474 21.0174 13.2888 21.9531C13.2928 21.955 13.2968 21.9568 13.3008
      21.9585C13.3656 21.9867 13.4333 22 13.5 22C13.5667 22 13.6344 21.9867 13.6992 21.9585Z'
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
    />
  </svg>
)
