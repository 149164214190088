import Link from 'next/link'
import Button from '@components/ui/Button'
import { BannerProps } from '.'
import sb from './Banner.module.css'

export const SmallBannerNineFit = ({
  background,
  title,
  subtitle,
  buttonText,
  link,
}: // size = 'big',
// isCentered = true,
BannerProps) => (
  <div className='md:w-1/2 w-full px-4 pb-4'>
    <div
      className='flex flex-col relative sm:p-10 p-5 justify-center items-center h-[22rem] rounded-banner lg:pt-44 lg:pb-44 sm:mt-0 mt-5 sm:h-[22rem]'
      style={{
        background: `url('${background}') center center no-repeat`,
        backgroundSize: '100% 70%',
        backgroundPosition: 'center 0',
      }}
    >
      <div
        className='flex flex-col max-w-xl bg-white pt-5 pb-5 items-center absolute rounded-banner bottom-0 h-[60%] sm:pl-10 sm:pr-10 lg:pl-20 lg:pr-20 text-center w-3/4'
        style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)' }}
      >
        <h3 className=' text-32 tracking-px block font-bold capitalize mb-6 '>
          {title}
        </h3>
        {subtitle && (
          <span className='block md:text-2xl text-base'>{subtitle}</span>
        )}
        {link ? (
          <Link className={sb.ninefitButton} href={link}>
            <Button variant='outlinedBrand'>{buttonText}</Button>
          </Link>
        ) : null}
      </div>
    </div>
  </div>
)
