import type { Product } from '@commerce/types/product'
import { Dispatch, SetStateAction } from 'react'

export type SelectedOptions = Record<string, string | null>

export function getProductVariant(product: Product, opts: SelectedOptions) {
  const currentVariant = product.variants?.find(variant => {
    return Object.entries(opts).every(([key, value]) =>
      // eslint-disable-next-line array-callback-return
      variant.options.find(option => {
        if (
          option.__typename === 'MultipleChoiceOption' &&
          option.displayName.toLowerCase() === key.toLowerCase()
        ) {
          return option.values.find(v => v.label.toLowerCase() === value)
        }

        return null
      }),
    )

    return null
  })

  return currentVariant
}

export function selectDefaultOptionFromProduct(
  product: Product,
  updater: Dispatch<SetStateAction<SelectedOptions>>,
) {
  // Selects the default option
  product.variants[0]?.options?.forEach(v => {
    updater(choices => ({
      ...choices,
      [v.displayName.toLowerCase()]: v.values[0].label.toLowerCase(),
    }))
  })
}
