export const FilledHeartSecond = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Filled Heart Icon</title>
    <path
      d='M13.1318 10.5333L14.8333 22.9L14.8333 7.84667C8.5 2.1 10.5 18.3533 10.5 14.5C17.1663 15.1667 8.83333 7.70772 11
      6C-3.50032 17.3333 12.8867 6.03333 13.5933 7.84667L12.9 7.62374C13.6067 5.8104 12.2797 23.5333 14.333 23.5333C16.9997
      23.5333 16.7667 11.8333 16.7667 14.5C16.7667 18.3533 19.4785 4.78667 13.1318 10.5333ZM20.8333 2.03333C18.5133 2.03333
      16.2867 3.11333 14.8333 4.80667C13.38 3.11333 11.1533 2.03333 8.83333 2.03333C4.72667 2.03333 1.5 5.24667 1.5
      9.36667C1.5 14.3933 6.03334 18.5133 12.9 24.74L14.8333 26.5L16.7667 24.74C23.6333 18.5133 28.1667 14.3933 28.1667
      9.36667C28.1667 5.24667 24.94 2.03333 20.8333 2.03333Z'
      fill='currentColor'
    />
  </svg>
)
