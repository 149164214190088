'use client'

import { CSSProperties, FC, useCallback, useState } from 'react'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { Product } from '@commerce/types/product'
import { ProductCard } from '@components/product'
import {
  CarouselButtonLeft,
  CarouselButtonRight,
  ChevronLeft,
  ChevronRight,
} from '@components/icons'
import { Swiper as SwiperType } from 'swiper/types'
import classNames from 'classnames'
import 'swiper/css'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import s from './Carousel.module.css'

interface CarouselProps extends Partial<SwiperProps> {
  products: Product[]
  slideClass?: string
  productCardClass?: string
  prevButtonClass?: string
  nextButtonClass?: string
  wrapperClass?: string
  wrapperStyles?: CSSProperties
  swipperStyles?: CSSProperties
  slideStyles?: CSSProperties
  cardContainerClass?: string
}

export const Carousel: FC<CarouselProps> = ({
  products,
  breakpoints,
  slideClass,
  productCardClass,
  prevButtonClass = 'left-0',
  nextButtonClass = 'right-0',
  wrapperClass,
  wrapperStyles,
  swipperStyles,
  slideStyles,
  cardContainerClass,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType>()
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnding, setIsEnding] = useState(false)
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slidePrev()
  }, [swiperRef])

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slideNext()
  }, [swiperRef])

  const onSwipe = (value: SwiperType) => {
    setIsBeginning(value.isBeginning)
    setIsEnding(value.isEnd)
  }

  return (
    <div style={wrapperStyles} className={`${wrapperClass || ''} ${s.root} `}>
      <Swiper
        className={s.swiper}
        modules={[Navigation]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        slidesPerView='auto'
        spaceBetween={20}
        onSwiper={setSwiperRef}
        style={swipperStyles}
        onSlideChange={onSwipe}
        breakpoints={breakpoints}
      >
        <div
          className={`${s.swiperBtnSmall} ${
            isBeginning && s.disabledSmall
          } swiper-button-prev right-10 lg:hidden`}
        >
          <ChevronLeft />
        </div>
        <div
          className={`${s.swiperBtnSmall} ${
            isEnding && s.disabledSmall
          } swiper-button-next right-0 lg:hidden`}
        >
          <ChevronRight />
        </div>
        <div className='max-w-6xl mx-auto'>
          {products.map((product: Product) => (
            <SwiperSlide
              style={{ minHeight: '100%', height: 'auto', ...slideStyles }}
              key={product.path}
              className={slideClass}
            >
              <div
                className={`pb-6 pt-10 lg:pt-6 h-full ${cardContainerClass}`}
              >
                <ProductCard
                  className={classNames(
                    'animated fadeIn sm:m-0 1xs:m-5',
                    productCardClass,
                  )}
                  product={product}
                  imgProps={{
                    width: isHomeBrand ? '100%' : 240,
                    height: isHomeBrand ? 'auto' : 240,
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
      <button
        aria-label='scroll product carousel left'
        type='button'
        onClick={handleLeftClick}
        className={classNames(
          s.swiperBtnBig,
          { [s.disabledBig]: isBeginning },
          'swiper-button-prev hidden lg:block',
          prevButtonClass,
        )}
      >
        <CarouselButtonLeft />
      </button>
      <button
        aria-label='scroll product carousel right'
        type='button'
        onClick={handleRightClick}
        className={classNames(
          s.swiperBtnBig,
          { [s.disabledBig]: isEnding },
          'swiper-button-next hidden lg:block',
          nextButtonClass,
        )}
      >
        <CarouselButtonRight />
      </button>
    </div>
  )
}
