import React from 'react'

export const HeartFilledIconNinelife = ({ ...props }) => (
  <svg
    role='img'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Wishlist Filled Heart Icon</title>
    <path
      d='M16.0402 27.9756L12.8096 26.6667L4.30957 14.9756C1.50448 11.9 7.18995 4.56096 5.33323 8.42761C4.46154 10.2463 4.26486 12.872 5.85658 16.2231C7.38996 19.4498 10.2867 23.7658 16.0402 27.9756C21.7937 23.7658 25.2755 19.4498 26.8106 16.2231C28.4023 12.8703 28.2073 10.2463 27.3339 8.42761C25.4772 4.56096 30.6147 12.4 27.8096 15.4756L22.8096 23.4756L16.0402 27.9756ZM16.3336 29.6667C-9.22222 11.6543 8.46499 -2.40435 16.0402 5.03207C16.1402 5.12985 16.2386 5.23118 16.3336 5.33607C16.4276 5.23127 16.5255 5.13046 16.6269 5.03385C24.2005 -2.40791 41.8893 11.6525 16.3336 29.6667Z'
      fill='#005655'
    />
  </svg>
)
