export const ShieldCheck = ({ className }: { className?: string }) => (
  <svg
    width='22'
    height='28'
    viewBox='0 0 22 28'
    fill='none'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.9997 2.00005L11.525 0.77338C11.359 0.70222 11.1803 0.665527 10.9997 0.665527C10.8191 0.665527 10.6403 0.70222 10.4743 0.77338L10.9997 2.00005ZM10.9997 26L10.3383 27.1574C10.5398 27.2724 10.7677 27.3329 10.9997 27.3329C11.2316 27.3329 11.4596 27.2724 11.661 27.1574L10.9997 26ZM5.69834 22.9694L5.03568 24.128L5.69701 22.9694H5.69834ZM10.4743 0.774714L1.94901 4.42671L2.99967 6.88005L11.525 3.22671L10.4743 0.774714ZM0.333008 6.87871V16.0254H2.99967V6.87871H0.333008ZM5.03568 24.128L10.3383 27.1574L11.661 24.8427L6.35834 21.812L5.03568 24.128ZM11.661 27.1574L16.9637 24.128L15.641 21.812L10.3383 24.8427L11.661 27.1574ZM21.6663 16.024V6.88005H18.9997V16.0267H21.6663V16.024ZM20.0503 4.42805L11.525 0.774714L10.4743 3.22538L18.9997 6.88005L20.0503 4.42805ZM21.6663 6.88005C21.6663 6.35826 21.5131 5.84795 21.2259 5.41234C20.9387 4.97673 20.5299 4.63363 20.0503 4.42805L18.9997 6.88005H21.6663ZM16.9637 24.128C18.3925 23.3116 19.5801 22.1331 20.406 20.7097C21.2318 19.2862 21.6667 17.6697 21.6663 16.024H18.9997C18.9995 17.1994 18.6887 18.3538 18.0986 19.3702C17.5085 20.3867 16.6602 21.2291 15.6397 21.812L16.9637 24.128ZM0.333008 16.024C0.332934 17.6695 0.76787 19.2857 1.59373 20.7089C2.41959 22.1321 3.60703 23.3117 5.03568 24.128L6.35834 21.812C5.33843 21.2291 4.49064 20.3871 3.90084 19.3711C3.31104 18.3552 3.00015 17.2015 2.99967 16.0267H0.333008V16.024ZM1.94901 4.42671C1.46922 4.63239 1.06034 4.97569 0.773081 5.41156C0.485819 5.84743 0.332805 6.35803 0.333008 6.88005H2.99967L1.94901 4.42938V4.42671Z'
      fill='#7E492A'
    />
    <path
      d='M15 11.3335L9.66667 16.6668L7 14.0002'
      stroke='#7E492A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
